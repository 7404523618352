<template>
  <div class="navbar">
    <div class="inner">
      <div class="left">
        <img src="../../assets/img/logo.png" alt="" class="img" />
        <span class="separate"></span>
      </div>
      <div class="center">
        <NuxtLink to="/">{{ $t("navbar.fontPage") }}</NuxtLink>
        <NuxtLink to="/gameServerList">{{ $t("navbar.gameList") }}</NuxtLink>
        <NuxtLink to="/helperCenter">{{ $t("navbar.helpCenter") }}</NuxtLink>
        <NuxtLink to="/newsCenter">{{ $t("navbar.newsCenter") }}</NuxtLink>
        <NuxtLink to="/aboutUs">{{ $t("navbar.aboutUs") }}</NuxtLink>
      </div>
      <div class="right">
        <!-- 搜索框 -->
        <el-input v-model="input1" :placeholder="$t('navbar.searchGame')" class="search" clearable
          @keydown.enter="handleSearch">
          <template #suffix>
            <el-icon @click="handleSearch">
              <Search />
            </el-icon>
          </template>
        </el-input>
        <!-- 语言切换 -->
        <div class="changeLanguage">
          <el-dropdown trigger="click" size="large" class="dropdown">
            <div class="el-dropdown-link">
              <!-- 国旗图标 -->
              <div class="icon1">
                <img :src="cnFlag" alt="" class="cn" v-if="locale == 'zh-CN' || locale == 'zh-HK'" />
                <img :src="usFlag" alt="" class="us" v-if="locale == 'en-US'" />
              </div>
              <span class="icon-info">{{ lang }}</span>
            </div>
            <template #dropdown>
              <el-dropdown-menu class="dropdown-menu">
                <el-dropdown-item class="item" @click="changeLanguage('zh-CN')">简体中文
                </el-dropdown-item>
                <el-dropdown-item class="item" @click="changeLanguage('zh-HK')">繁體中文
                </el-dropdown-item>
                <el-dropdown-item class="item" @click="changeLanguage('en-US')">English
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>

        <!-- 登录注册 -->
        <div class="login-register" v-if="!loginStore.getToken()">
          <div class="login-btn" @click="handleLogin('login')">
            <font-awesome-icon :icon="['far', 'user']" class="icon" />
            {{ $t("navbar.login") }}
          </div>

          <el-button color="#2f323a" class="register-btn" @click="handleLogin('register')">
            {{ $t("navbar.register") + " >" }}
          </el-button>
        </div>
        <!-- 用户中心按钮 -->
        <div class="user-center" v-if="loginStore.getToken()">
          <el-button color="#2f323a" @click="handleUserCenter">
            {{ $t("navbar.userCenter") + " >" }}
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { Search } from "@element-plus/icons-vue"
import { useI18n } from "vue-i18n"

import cnFlag from "@/assets/img/navbar-pic/cn-flag1.png"
import usFlag from "@/assets/img/navbar-pic/us-flag1.png"
import useLoginStore from "@/stores/login/login"

const loginStore = useLoginStore()
const { locale, t } = useI18n()
const route = useRoute()
const input1 = ref(route.query.search ?? "")
const lang = ref("简体中文")

onMounted(() => {
  lang.value =
    locale.value == "zh-CN"
      ? (lang.value = "简体中文")
      : locale.value == "zh-HK"
        ? (lang.value = "繁體中文")
        : (lang.value = "English")
})

// 语言改变
const changeLanguage = (language) => {
  locale.value = language
  language == "zh-CN"
    ? (lang.value = "简体中文")
    : language == "zh-HK"
      ? (lang.value = "繁體中文")
      : (lang.value = "English")
}

// 登录注册
const handleLogin = (flag) => {
  navigateTo({ path: "/login", query: { flag } })
}

// 搜索
const handleSearch = () => {
  navigateTo({
    path: "/gameServerList",
    query: {
      search: input1.value,
    },
  })
  // console.log(route.path);
}

// 跳转到用户中心
const handleUserCenter = () => {
  navigateTo({
    path: "/userCenter",
  })
}
</script>

<style lang="less" scoped>
.navbar {
  position: fixed;
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  height: 58px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 97;
  padding: 6px 0;
  background-color: var(--bg-color);
  display: flex;
  align-items: center;

  &::-webkit-scrollbar {
    // width: 2px;
    height: 8px;
    background-color: #f5f5f5;
  }

  /*定义滚动条轨道：内阴影+圆角*/
  &::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }

  /*定义滑块：内阴影+圆角*/
  &::-webkit-scrollbar-thumb {
    border-radius: 0.059rem;
    background-color: #555;

    &:hover {
      cursor: pointer;
    }
  }

  .inner {
    width: 1405px;
    height: 42.5px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;

      .separate {
        display: block;
        margin-left: 34px;
        border-right: 0.89px solid rgba(36, 37, 39, 1);
        height: 28px;
      }

      .img {
        width: 130px;
      }
    }

    .center {
      width: 506px;
      color: #fff;
      font-size: 17px;
      height: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      margin-left: 36px;

      a {
        color: var(--text-color);

        &:hover {
          color: #0094d7;
        }
      }
    }

    .right {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-left: 60px;
      width: 641px;

      .el-input {
        width: 260px;
        height: 40px;
      }

      ::v-deep .el-input__wrapper {
        background-color: #2f323a !important;
        border: none !important;
        box-shadow: none !important;

        border-radius: 4px;

        .el-input__inner {
          font-size: 16px;

          &:focus {
            color: #fff;
          }
        }

        span {
          cursor: pointer;
        }
      }

      .changeLanguage {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        user-select: none;
        margin-left: 30px;
        // margin-right: 6px;

        .dropdown {
          left: 0;
          top: 0;

          .el-dropdown-link {
            display: flex;
            align-items: center;
            font-size: 18px;

            .icon1 {
              // margin-left: 40px;
              margin-right: 10px;

              .cn,
              .us,
              .hk {
                width: 44px;
                height: 30px;
                border-radius: 2px;
              }
            }

            .icon-info {
              display: block;
              font-size: 17px;
              width: 90px;
            }
          }
        }
      }

      .login-register {
        display: flex;
        align-items: center;
        width: 234px;

        .login-btn {
          width: 80px;
          height: 22px;
          font-size: 18px;
          cursor: pointer;

          .icon {
            margin-left: 8px;
          }

          &:hover {
            color: #0094d7;
          }
        }

        .register-btn {
          width: 152px;
          height: 40px;
          background-image: linear-gradient(to right,
              rgba(57, 84, 230, 1),
              rgba(101, 124, 250, 1));
          font-size: 18px;
        }
      }

      .user-center {
        cursor: pointer;
        width: 234px;
        text-align: center;

        .el-button {
          font-size: 17px;
          width: 152px;
          height: 40px;
          background-image: linear-gradient(to right,
              rgba(57, 84, 230, 1),
              rgba(101, 124, 250, 1));
        }
      }
    }
  }
}

.dropdown {
  position: relative;
  top: -0.5px;
  left: 14px;
  color: var(--text-color);
  cursor: pointer;
  font-size: 18px;

  .icon1 {
    position: relative;
  }

  .icon2 {
    color: var(--text-color);

    .icon2-arrow {
      width: 100%;
      height: 100%;
    }
  }
}

.dropdown-menu {
  background-color: var(--nav-color);

  ::v-deep .item {
    font-size: 16px;
    color: var(--text-color);
    background-color: var(--nav-color);

    &:hover {
      background-color: #2f323a;
    }
  }

  ::v-deep .el-dropdown-menu__item:not(.is-disabled):focus {
    background-color: #0d1117;
    color: #8b8d97;
  }
}
</style>
